<template lang="pug">
div(v-if="Object.keys(powersOfAttorney).length").bg-white.p-3
  div.flex-row-sb.justify-between.w-100.p-0
    div.text-left.text-uppercase.p-0
      | {{ $t(`document-powersofattorney-${Object.keys(powersOfAttorney?.behavior)[0]}`, { id: powersOfAttorney?.id }) }}
    div.documentIconControl
      ControlButtons(:controlButtons="controlButtons")
  Info(v-if="powersOfAttorney?.behavior?.viewInfoBlock" :powersOfAttorney="powersOfAttorney")
  Reject(v-if="powersOfAttorney?.behavior?.viewRejectBlock" :powersOfAttorney="powersOfAttorney")
  ViewPhotoList(
    v-else-if="powersOfAttorney?.behavior?.viewFilesBlock"
    :sailorDocument="powersOfAttorney"
    documentType="powersOfAttorney"
    )
</template>

<script>
import { mapActions } from 'vuex'
import { viewDetailedComponent, addNeedFieldForViewFiles } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
import { STATUSES_DOCUMENT_CONSTANTS } from '@/configs/constants'
export default {
  components: {
    Info: () => import('./Info.vue'),
    Reject: () => import('./Reject.vue'),
    ViewPhotoList: () => import('@/components/atoms/ViewPhotoList.vue'),
    ControlButtons: () => import('@/components/atoms/ControlButtons.vue')
  },
  data () {
    return {
      powersOfAttorney: {},
      controlButtons: [
        {
          id: 1,
          name: 'mdi-account-arrow-right-outline',
          action: () => this.$router.push(`/sailor/${this.powersOfAttorney.sailor.id}`),
          checkAccess: () => STATUSES_DOCUMENT_CONSTANTS.approvedPowersOfAttorney === this.powersOfAttorney.status_document,
          color: '#42627e',
          tooltip: 'documentIconControl.go'
        },
        {
          id: 3,
          name: 'mdi-information-outline',
          action: () => viewDetailedComponent(this.powersOfAttorney, 'viewInfoBlock'),
          checkAccess: () => checkAccess('powersOfAttorney', 'view'),
          color: '#42627e',
          tooltip: 'documentIconControl.info'
        },
        {
          id: 2,
          name: 'mdi-file-document-check-outline',
          action: () => this.handleClickApproveDocument(),
          checkAccess: () => checkAccess('powersOfAttorney', 'view') &&
            ![STATUSES_DOCUMENT_CONSTANTS.rejectedPowersOfAttorney, STATUSES_DOCUMENT_CONSTANTS.approvedPowersOfAttorney].includes(this.powersOfAttorney.status_document),
          color: 'green',
          tooltip: 'documentIconControl.reject'
        },
        {
          id: 4,
          name: 'mdi-file-document-remove-outline',
          action: () => viewDetailedComponent(this.powersOfAttorney, 'viewRejectBlock'),
          checkAccess: () => checkAccess('powersOfAttorney', 'view') &&
            ![STATUSES_DOCUMENT_CONSTANTS.rejectedPowersOfAttorney, STATUSES_DOCUMENT_CONSTANTS.approvedPowersOfAttorney].includes(this.powersOfAttorney.status_document),
          color: 'red',
          tooltip: 'documentIconControl.reject'
        },
        {
          id: 6,
          name: 'mdi-image-outline',
          action: () => viewDetailedComponent(this.powersOfAttorney, 'viewFilesBlock'),
          checkAccess: () => checkAccess('powersOfAttorney', 'view_file'),
          color: '#42627e',
          tooltip: 'documentIconControl.viewfiles'
        },
        {
          id: 7,
          name: 'mdi-close',
          action: () => this.$router.push('/back-office/powers-of-attorney'),
          checkAccess: () => true,
          color: '#42627e',
          tooltip: 'documentIconControl.close'
        }
      ]
    }
  },
  computed: {
  },
  mounted () {
    this.getData()
  },
  methods: {
    ...mapActions(['getPowersOfAttorneyById', 'uploadPowersOfAttorneyById']),
    async getData () {
      const { id } = this.$route.params
      const response = await this.getPowersOfAttorneyById({ id })
      response.files = addNeedFieldForViewFiles(response.files)
      this.powersOfAttorney = response
    },
    handleClickApproveDocument () {
      this.$swal({
        title: this.$t('Do you want to approve this a powers of attorney ?'),
        buttons: [this.$t('no'), this.$t('yes')]
      }).then(async (confirm) => {
        if (confirm) {
          let data = {
            body: { status_document: STATUSES_DOCUMENT_CONSTANTS.approvedPowersOfAttorney },
            id: this.powersOfAttorney.id
          }
          this.powersOfAttorney = {}
          this.powersOfAttorney = await this.uploadPowersOfAttorneyById(data)
        }
      })
    }
  }
}
</script>
